<template>
  <div v-if="source" class="responsive-height-aspect-ratio-16-9">
    <div class="absolute h-full w-full">
      <div class="flex h-full w-full">
        <iframe
          ref="player"
          data-testid="vimeo-player"
          :src="urlVideo"
          allowfullscreen
          class="h-full w-full"
          allow="autoplay; fullscreen; picture-in-picture; encrypted-media; accelerometer; gyroscope"
          :title="title"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useNuxtApp } from '#app'
import { useVideosStore } from '~/stores/videos'

type TVimeoEventParams = {
  duration: number
  seconds: number
  percent: number
}

const defaultOptions = {
  loop: false,
  autoplay: true,
  muted: false,
  playsinline: true,
  byline: false,
  portrait: false,
  title: false,
  speed: true,
  transparent: false,
  captions: false,
  pip: false,
  color: 'FDBD00',
  endscreen: 'void',
  fields: 'play.progressive',
}

const props = withDefaults(
  defineProps<{
    source: string | number
    autoplay?: boolean
    title?: string
    ratio?: string
    isFree?: boolean
  }>(),
  {
    autoplay: true,
    title: '',
    ratio: '1920:1080',
    isFree: false,
  },
)

const vimeoData = ref<TVimeoData>(null)
const videosStore = useVideosStore()
const { $rudderstack } = useNuxtApp()
const player = ref<HTMLIFrameElement | null>(null)
const { $vimeo } = useNuxtApp()
const emit = defineEmits(['endedVideo', 'playVideo'])

const urlVideo = computed(() => {
  const url = new URL(props.source.toString())
  Object.entries(defaultOptions).forEach(([key, value]) => {
    url.searchParams.set(key, value.toString())
  })
  return url.toString()
})

const getIdFromSource = () => {
  const url = new URL(props.source.toString())
  return url.pathname.split('/').pop()
}

const onVideoEnded = (params: TVimeoEventParams) => {
  if (!params) return

  emit('endedVideo')
  $rudderstack.trackVideoEnded({
    video_id: getIdFromSource(),
    video_title: vimeoData.value?.name,
    video_duration: secondsToHHMMSS(vimeoData.value?.duration),
    videoType: props.isFree ? 'Free' : 'Paid',
  })
}

const onVideoInit = (params: TVimeoEventParams) => {
  if (!params) return

  emit('playVideo')
  $rudderstack.trackVideoStarted({
    video_id: getIdFromSource(),
    video_title: vimeoData.value?.name,
    video_duration: secondsToHHMMSS(vimeoData.value?.duration),
    video_percentage: `${params.percent * 100}%`,
    videoType: props.isFree ? 'Free' : 'Paid',
  })
}

const onVideoProgress = (params: TVimeoEventParams) => {
  if (!params) return

  $rudderstack.trackVideoProgress({
    video_id: getIdFromSource(),
    video_title: vimeoData.value?.name,
    video_duration: secondsToHHMMSS(vimeoData.value?.duration),
    video_percentage: `${params.percent * 100}%`,
    videoType: props.isFree ? 'Free' : 'Paid',
  })
}

onMounted(() => {
  if (!player.value) return

  const vimeoPlayer = $vimeo.init(player.value, {
    ratio: props.ratio,
  })

  vimeoData.value = videosStore.getVideo(getIdFromSource())

  vimeoPlayer.on('ended', onVideoEnded)
  vimeoPlayer.on('play', onVideoInit)
  vimeoPlayer.on('progress', onVideoProgress)
})
</script>

<style scoped lang="scss">
.responsive-height-aspect-ratio-16-9 {
  position: relative;
  margin: 0 auto;
  aspect-ratio: 16/9;
  max-width: 100vw;
}
</style>
