// Create a store to store data in a format of Record<string, any> and use it to store the data from the API.

import type { TVimeoData } from '~/composables/useGetVimeoData'

export const useVideosStore = defineStore('videos', {
  state: () => ({
    videos: {} as Record<string | number, TVimeoData>,
  }),
  getters: {
    getVideo: (state) => (videoId: string | number) => {
      return state.videos[videoId]
    },
  },
  actions: {
    setVideo(videoId: string | number, videoData: TVimeoData) {
      this.videos[videoId] = videoData
    },
  },
})
